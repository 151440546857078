import React from 'react';
import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import { MAIN_COLOR } from 'src/consts';
import { InputAdornment } from '@material-ui/core/';
import { PermIdentity, EmailOutlined, EditOutlined } from '@material-ui/icons/';

export const ContactContent: React.FC = (props) => {
  return (
    <div
      css={css`
        display: flex;
        margin-top: 5rem;
      `}
    >
      <div
        css={css`
          margin: auto;
          width: min(100%, 60rem);
          display: flex;
          background-color: rgba(0, 0, 0, 0.9);
          padding: 2rem;
          flex-direction: column;
          row-gap: 1rem;
          border-radius: 20px;
        `}
        {...props}
      >
        <TextField
          label="Nombre"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentity />
              </InputAdornment>
            ),
          }}
          css={css`
            background-color: white;
            grid-column: 1;
            border-radius: 10px;
          `}
        />
        <TextField
          label="Mail"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
          css={css`
            margin-top: 1rem;
            background-color: white;
            grid-column: 1;
            grid-row: 2;
            border-radius: 10px;

          `}
        />
        <TextField
          label="Mensaje"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" >
                <EditOutlined css={css`margin-bottom: 5rem;`}/>
              </InputAdornment>
            ),
          }}
          multiline
          rows={4}
          css={css`
            margin-top: 1rem;
            background-color: white;
            grid-column: 2;
            grid-row: 1 / 3;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          `}
        />
      </div>
    </div>
  );
};

export default ContactContent;
