import React from 'react';
import { css } from '@emotion/core';
import { Layout, Main } from 'src/components/layout';
import { RootWrapper } from 'src/components/RootWrapper';
import { IndexHeader, IndexFooter } from 'src/components/pages/index';
import { TextContextProvider } from 'src/components/TextContext';
import { ContactContent } from 'src/components/pages/contacto/ContactContent';

import esTextCommon from '../../text/es/common.yaml';
import esTextQuienesSomos from '../../text/es/quienes-somos.yaml';
import enTextCommon from '../../text/en/common.yaml';
import enTextQuienesSomos from '../../text/en/quienes-somos.yaml';

const esText = { ...esTextCommon, ...esTextQuienesSomos };
const enText = { ...enTextCommon, ...enTextQuienesSomos };
const text = {
  es: esText,
  en: enText,
};

const ContactoPage = ({ location }) => {
  return (
    <RootWrapper>
      <TextContextProvider text={text} initialLanguage={'es'}>
        <Layout>
          <IndexHeader backgroundVisibility={1} location={location} />
          <Main
            css={css`
              margin-top: 4rem;
            `}
          >
            <ContactContent />
          </Main>
          <IndexFooter />
        </Layout>
      </TextContextProvider>
    </RootWrapper>
  );
};

export default ContactoPage;
